import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const PREFIX = 'Landing';

const classes = {
    cover: `${PREFIX}-cover`,
    link: `${PREFIX}-link`,
    center: `${PREFIX}-center`
};

export function Landing() {
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
            sx={{ color: theme => theme.palette.common.white, textShadow: '1px 1px 4px black', display: 'flex', flex: 1 }}>
            <Grid item>
                <Typography variant="h2">Víg Büfé</Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">Étel-ital szolgáltatás előrendeléssel, soron kívül</Typography>
            </Grid>
            <Grid item sx={{ paddingTop: '1rem' }}>
                <Link to="/order">
                    <Button variant="contained" color="primary">Rendelés</Button>
                </Link>
            </Grid>
        </Grid>
    );
}
